import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import IndividualPageLayoutTemplate from '../components/IndividualPageLayoutTemplate'


export const IndividualCapabilitiesTemplate = ({
    pageName,
    bannerImg,
    bannerTitle,
    bannerDescription,
    introSectionTitle,
    introSectionDescription,
    // spotlightActivity,
    topicList
}) => {



  return (
    <>
    <IndividualPageLayoutTemplate 
        pageName={pageName}
        bannerImg={bannerImg}
        bannerTitle={bannerTitle}
        bannerDescription={bannerDescription}
        introSectionTitle={introSectionTitle}
        introSectionDescription={introSectionDescription}
        topicList={topicList}
        />
    </>
  )
}

IndividualCapabilitiesTemplate.propTypes = {
    pageName: PropTypes.string,
    bannerImg: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    bannerTitle: PropTypes.string,
    bannerDescription: PropTypes.string,
    introSectionTitle: PropTypes.string,
    introSectionDescription: PropTypes.string,
    topicList: PropTypes.array
  }

  const IndividualCapabilitiesPage = ({ data }) => {
    const { frontmatter } = data.markdownRemark
      return (
        <Layout
        title={frontmatter.bannerTitle}
        description={frontmatter.bannerDescription}
        >
              <IndividualCapabilitiesTemplate
                pageName={frontmatter.pageName}
                bannerImg={frontmatter.bannerImg}
                bannerTitle={frontmatter.bannerTitle}
                bannerDescription={frontmatter.bannerDescription}
                introSectionTitle={frontmatter.introSectionTitle}
                introSectionDescription={frontmatter.introSectionDescription}
                topicList={frontmatter.topicList}
              />
        </Layout>
      )
}


IndividualCapabilitiesPage.propTypes = {
    data: PropTypes.shape({
    markdownRemark: PropTypes.object,
    }),
}

export default IndividualCapabilitiesPage


export const pageQuery = graphql`
  query CapabilitiesPostID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        pageName
        bannerImg {
            extension
            publicURL
          }
        bannerTitle
        bannerDescription
        introSectionTitle
        introSectionDescription
        topicList {
          title
          description
          thumbnail {
            extension
            publicURL
          }
        }
      }
    }
  }
`
