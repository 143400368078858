import React from 'react'
import PageBannerImgBackground from '../PageBannerImgBackground/pageBannerImgBackground'
import SpotlightActivity from '../../components/SpotlightActivity/spotlightActivity'
import './individualPageLayoutTemplate.scss'


const IndividualPageLayoutTemplate = ({ 
    bannerImg,
    bannerTitle,
    bannerDescription,
    introSectionTitle,
    introSectionDescription,
    spotlightActivity,
    topicList }) => {


        const getImagePath = (image) => {
            if (image?.publicURL) return image.publicURL
            else return image
          }


    return (
        <div className='individual-page-layout-wrapper'> 
        <PageBannerImgBackground
        imgPath={getImagePath(bannerImg)}
        title={bannerTitle}
        description={bannerDescription}
        />

        <div className='body-content-wrapper'>

        <div className='intro-statement-block'>
                <h2> {introSectionTitle}</h2>
                <div  dangerouslySetInnerHTML={{ __html: introSectionDescription }} />
        </div>
        
            {topicList && topicList.map(item => <div className='description-block'>
                <div className='image-wrapper' style={{background: `url(${getImagePath(item.thumbnail)} )`}}> 
                    {/* <img src={getImagePath(item.thumbnail)} /> */}
                </div>
                    <div className='description-wrapper'>
                        <div className='title'>
                            {item.title}
                        </div>
                        <div className='description'  dangerouslySetInnerHTML={{ __html: item.description }} />
                    </div>
            </div>)}
        
        </div>

    </div>
    )
}


export default  IndividualPageLayoutTemplate
